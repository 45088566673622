/* eslint-disable import/extensions */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import scriptLoader from 'react-async-script-loader'
import { setOrder, setOrderList, setOrderNumber } from '@redux/modules/orderStatus'
import loaderDark from '@assets/images/loader-dark.svg'
import useHasWindow from '@helpers/hooks/useWindow'
import { getOrderOnRefresh } from './helpers'
import { onPageLoad, onSubmit } from './reschedule'
import './main.sass'

require('./reschedule.js')

const doorwayDeliveryMessage = `You have chosen Doorway Delivery and your order will be placed inside the garage or entryway of your home. Professional assembly and removal of packaging is not included.`
const professionalDeliveryMessage = `Professional delivery and setup to any room<br/>Premium Delivery includes delivery to any room, professional assembly and removal of packaging.<br/>4-hour delivery window</span><br/><em>We will call 2 day prior with the estimated delivery window.`
// const premiumDeliveryMessage = `Premium delivery includes delivery to any room, professional assembly and removal of packaging.`
// const upgradeDeliveryMessage = `<span class="">Professional Delivery</span>`
// const selectedDate = theDeliveryDate =>
//   `I agree to the delivery date of <span class="delivery-date">${theDeliveryDate}</span>. I understand that the 4 hour delivery window is within 7am to 10pm and that I will be contacted approximately 2 days prior to be notified of a 4 hour delivery window. Note: Scheduled delivery dates are subject to change. `

const RescheduleDelivery = () => {
  const dispatch = useDispatch()
  const { order, orderNumber, orderList } = useSelector(state => state.orderStatus)
  const [pageLoaded, setPageLoaded] = useState(false)
  const { DoorwayDelivery, upgradeDelivery } = order
  const deliveryType = order.DeliveryType
  const isWindow = useHasWindow()

  const checkOrder = async () => {
    // * On Refresh we want to check for order number and call api to update orderList
    if (!pageLoaded) {
      if (!orderList?.length && !orderNumber) {
        await getOrderOnRefresh({ dispatch, setOrder, setOrderList, setOrderNumber })
        onPageLoad()
        setPageLoaded(true)
      } else {
        onPageLoad()
        setPageLoaded(true)
      }
    }
  }

  const getDeliveryMessage = () => {
    if (DoorwayDelivery === false && (deliveryType === 'D' || deliveryType === 'K')) {
      return (
        <div className="address-line delivered2">
          <p dangerouslySetInnerHTML={{ __html: professionalDeliveryMessage }} />
        </div>
      )
    }
    return (
      <>
        {DoorwayDelivery === true && (
          <div className="address-line delivered">
            <p dangerouslySetInnerHTML={{ __html: doorwayDeliveryMessage }} />
          </div>
        )}

        {upgradeDelivery === true && deliveryType !== 'U' && (
          <div className="address-line upgrade">
            {/* <p dangerouslySetInnerHTML={{ __html: upgradeDeliveryMessage }} /> */}
          </div>
        )}
      </>
    )
  }

  if (isWindow) {
    checkOrder()
  }

  return (
    <>
      {order && getDeliveryMessage && (
        <>
          <section className="loading">
            <img alt="" src={loaderDark} className="spinner" />
          </section>

          <section className="errors order-section hide" />

          <div className="main-section hide">
            <input type="hidden" value="" id="selected_date" />
            <section className="date-selection order-section deliveryinfo">
              <div className="info ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon shipping small-5 cell"
                  viewBox="0 0 65 30"
                  role="presentation"
                  aria-hidden="true"
                  gtm-category="header"
                  gtm-action="shipping focus"
                  gtm-label="click"
                >
                  <path
                    fill="#010101"
                    d="M19.1 26.4h4.1c.1 0 .3 0 .4-.1.4 2.3 2.6 3.9 4.9 3.5 1.8-.3 3.2-1.7 3.5-3.5h17.1c.4 2.3 2.6 3.9 4.9 3.5 1.8-.3 3.2-1.7 3.5-3.5H61c.6 0 1-.5 1-1V14c0-2.9-.8-5.3-2.4-7-1.5-1.6-3.6-2.5-5.8-2.4h-6.1V1c0-.6-.5-1-1-1H19.1c-.6 0-1 .5-1 1v24.4c0 .6.4 1 1 1zm34.3 1.5c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2 2.2 1 2.2 2.2c0 1.2-1 2.2-2.2 2.2zM47.7 6.5h6.1c3.8 0 6.1 2.8 6.1 7.3v10.4h-2.5c-.6-1.7-2.2-2.8-4-2.9-1.8 0-3.4 1.2-4 2.9h-1.6l-.1-17.7zM27.8 27.9c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2 2.2 1 2.2 2.2c0 1.2-.9 2.2-2.2 2.2zM20.1 2.1h25.4v22.2H32.3c-.1 0-.3 0-.4.1-.6-1.8-2.2-3-4.1-2.9-1.9 0-3.5 1.2-4.1 3-.2-.1-.3-.1-.5-.1h-3.1V2.1zM3 7.2c0-.6.5-1 1-1h10c.6 0 1 .5 1 1 0 .6-.5 1-1 1H4c-.5 0-1-.4-1-1zm11.1 7.1H5.6c-.6 0-1-.5-1-1 0-.6.5-1 1-1H14c.6 0 1 .5 1 1 .1.5-.3 1-.9 1zM6 19.2c0-.6.5-1 1-1h7c.6 0 1 .5 1 1s-.5 1-1 1H7c-.5 0-1-.4-1-1z"
                  />
                </svg>
                {getDeliveryMessage()}
                <div className="address-line delivery" />
              </div>
              <div className="calendar-box">
                <h3 id="calendar-instructions">Change Your Delivery</h3>
                <br />
                <div className="calendar-holder" />
                <div className="keys">
                  <div className="key selected">
                    <span>×</span>Selected
                  </div>
                  <div className="key ">
                    <span>×</span>Available
                  </div>
                </div>
              </div>

              <ul className="date-list" />
            </section>

            <div className="col read-terms">
              <label>
                <input type="checkbox" name="toc-chk" value="1" />
                <p />
              </label>
            </div>
            <div className="has-errors hide" />
            <button
              type="button"
              id="update-button"
              className="btn btn-primary primary"
              onClick={e => onSubmit(e, order)}
            >
              Change Delivery Date
            </button>
          </div>

          <section className="confirm order-section hide" />
        </>
      )}
    </>
  )
}

export default scriptLoader([`https://code.jquery.com/jquery-3.5.1.min.js`])(RescheduleDelivery)
