import React from 'react'
import Helmet from 'react-helmet'
import Layout from '@components/layout'
import RescheduleDelivery from '@components/order/status/order-reschedule'

const Status = () => (
  <Layout>
    <Helmet title="Order Reschedule Delivery - Rooms To Go" />
    <RescheduleDelivery />
  </Layout>
)

export default Status
