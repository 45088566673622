/* eslint-disable */
import loaderLight from '@assets/images/loader-light.svg'
import { getFromBrowserStorage } from '@helpers/storage'

const ordereendpoint = process.env.GATSBY_ORDER_MANAGEMENT_URL
const deliveryendpoint = process.env.GATSBY_DELIVERY_SERVICE_URL
const redirurl = '/order/details'

let selectedDate = ''
let activeDate = ''

const urlParam = name => {
  const results = new RegExp(`[?&]${name}=([^&#]*)`).exec(window?.location.href)
  if (results == null) {
    return null
  }

  return decodeURI(results[1]) || 0
}

const scrollTo = element => {
  setTimeout(() => {
    if (document.getElementById(element)) {
      window?.scroll({
        top: document.getElementById(element).offsetTop - 25 - document.getElementsByClassName('head')[0].offsetHeight,
      })
    }
  }, 1000)
}

/* Load Calendar */
const loadcalendar = (target, available_dates) => {
  const today = new Date()
  let currentMonth = today.getMonth()
  let currentYear = today.getFullYear()
  const activedate = ''

  const initCal = `<div class="calendar-wrap hide">
	<h3 id="calendar-title" class="calendar-title"></h3>
	<div class="calendar-buttons">
	<button  aria-label="Previous Month" aria-describedby="calendar-instructions calendar-title" class="prev">&nbsp;</button>
	<button  aria-label="Next Month" aria-describedby="calendar-instructions calendar-title"  class="next">&nbsp;</button>
	</div>
	<div class="calendar-table">
	<div class="weekdays"></div>
	<div class="days"></div>
	</div>	
	</div>
	`

  let calwrap = document.querySelector('.calendar-wrap')
  if (typeof calwrap !== 'undefined' && calwrap != null) {
    calwrap.remove()
    document.addEventListener('click', e => {
      const t = e.target
      if (t.closest('.calendar-wrap') == null && t !== target && !t.classList.contains('ravacal')) {
        calwrap = document.querySelector('.calendar-wrap')
        if (typeof calwrap !== 'undefined' && calwrap != null) {
          // calwrap.remove();
        }
      }
    })
  }

  if (document.querySelector('.main-section .calendar-holder'))
    document.querySelector('.main-section .calendar-holder').insertAdjacentHTML('beforeend', initCal)
  /* set calendar position on screen based on the target */
  calwrap = document.querySelector('.calendar-wrap')

  if (calwrap) {
    calwrap.style.top = `${target.offsetTop + target.offsetHeight}px`
    calwrap.style.left = `${target.offsetLeft}px`
  }

  let start_month
  let end_month
  let end_year
  let start_val
  let end_val = ''

  available_dates.map((dt, i) => {
    const dtarr = dt.split('-')
    if (i === 0) {
      start_month = parseInt(dtarr[1])
      currentMonth = parseInt(dtarr[1]) - 1
      currentYear = parseInt(dtarr[0])
    }
    end_month = parseInt(dtarr[1]) - 1
    end_year = parseInt(dtarr[0])
  })

  start_val = start_month + currentYear * 12
  end_val = end_month + end_year * 12

  if (selectedDate !== '') currentMonth = parseInt(selectedDate.getMonth())
  if (selectedDate !== '') currentYear = parseInt(selectedDate.getFullYear())

  showCalendar(calwrap, activedate, currentMonth, currentYear, available_dates)

  if (currentMonth + currentYear * 12 < start_val)
    document.querySelector('.calendar-buttons .prev').classList.add('disabled')
  if (currentMonth + currentYear * 12 + 2 > end_val)
    document.querySelector('.calendar-buttons .next').classList.add('disabled')

  document.querySelector('.calendar-buttons .next').addEventListener('click', () => {
    if (currentMonth + currentYear * 12 + 1 > end_val) return false
    document.querySelector('.calendar-buttons .prev').classList.remove('disabled')
    document.querySelector('.calendar-buttons .next').classList.remove('disabled')
    if (currentMonth + currentYear * 12 + 2 > end_val)
      document.querySelector('.calendar-buttons .next').classList.add('disabled')
    currentYear = currentMonth === 11 ? parseInt(currentYear) + 1 : currentYear
    currentMonth = (parseInt(currentMonth) + 1) % 12

    showCalendar(calwrap, activedate, currentMonth, currentYear, available_dates)
  })

  document.querySelector('.calendar-buttons .prev').addEventListener('click', () => {
    if (currentMonth + currentYear * 12 < start_val) return false
    document.querySelector('.calendar-buttons .next').classList.remove('disabled')
    document.querySelector('.calendar-buttons .prev').classList.remove('disabled')
    if (currentMonth + currentYear * 12 - 1 < start_val)
      document.querySelector('.calendar-buttons .prev').classList.add('disabled')
    currentYear = currentMonth === 0 ? parseInt(currentYear) - 1 : currentYear
    currentMonth = currentMonth === 0 ? 11 : parseInt(currentMonth) - 1

    showCalendar(calwrap, activedate, currentMonth, currentYear, available_dates)
  })

  calwrap.addEventListener('click', e => {
    if (e.target.classList.contains('disabled')) return false

    if (e.target.classList.contains('day') || e.target.parentNode.classList.contains('day')) {
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
      const theday = e.target
      const d = theday.getAttribute('data-date')
      const m = theday.getAttribute('data-month')
      const y = theday.getAttribute('data-year')
      Array.from(document.querySelectorAll('.date-picker')).map(item => {
        item.classList.remove('selected')
        item.removeAttribute('aria-selected')
        item.setAttribute('tabindex', '-1')
      })
      e.target.classList.add('selected', 'sel')
      e.target.setAttribute('aria-selected', 'true')
      e.target.setAttribute('tabindex', '0')
      if (m != null) {
        // if (!target.innerText) {
        //   target.value = `${m}/${d}/${y}`
        // } else {
        //   target.innerText = `${m}/${d}/${y}`
        // }

        const event = document.createEvent('Event')
        event.initEvent('input', true, true)
        // target.dispatchEvent(event);
        document.querySelector('#selected_date').value = `${y}-${pad(m, 2)}-${pad(d, 2)}`
        selectedDate = `${y}-${pad(m, 2)}-${pad(d, 2)}`
        activeDate = `${y}-${m}-${d}`

        const tmpdate = new Date(`${selectedDate}T12:00:00Z`)
        const theDeliveryDate = `${weekdays[tmpdate.getDay()]}, ${
          months[tmpdate.getMonth()]
        } ${tmpdate.getDate()}, ${tmpdate.getFullYear()}`
        document.querySelector('.read-terms p .delivery-date').innerHTML = theDeliveryDate
      }
      // calwrap.remove();
    }
  })
}

const showCalendar = (calwrap, activedate, month, year, available_dates, target) => {
  const today = new Date()
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const firstDay = new Date(year, month).getDay()
  const weekdisplay = calwrap.querySelector('.calendar-table .weekdays')
  weekdisplay.innerHTML = `<div class="row">
	<div id="day0" aria-label="Sunday"  class="day">Su</div>
	<div id="day1" aria-label="Monday"  class="day">Mo</div>
	<div id="day2" aria-label="Tuesday" class="day">Tu</div>
	<div id="day3" aria-label="Wednesday" class="day">We</div>
	<div id="day4" aria-label="Thursday" class="day">Th</div>
	<div id="day5" aria-label="Friday" class="day">Fr</div>
	<div id="day6" aria-label="Saturday" class="day">Sa</div>
	</div>`

  const daydisplay = calwrap.querySelector('.calendar-table .days')
  daydisplay.innerHTML = ''

  const monthAndYear = calwrap.querySelector('.calendar-wrap .calendar-title')
  monthAndYear.innerHTML = `${months[month]} ${year}`

  // creating all cells
  let date = 1
  const daysinmonth = 32 - new Date(year, month, 32).getDate()

  for (let i = 0; i < 6; i++) {
    const row = document.createElement('div')
    row.classList.add('row')
    if (date > daysinmonth) {
      break
    }
    for (let j = 0; j < 7; j++) {
      if (i === 0 && j < firstDay) {
        const cell = document.createElement('div')
        cell.classList.add('day')
        row.append(cell)
      } else if (date > daysinmonth) {
        const cell = document.createElement('div')
        cell.classList.add('day')
        row.append(cell)
      } else {
        const cell = document.createElement('div')
        cell.classList.add('day')
        cell.setAttribute('role', 'button')
        cell.setAttribute('tabindex', '-1')
        cell.setAttribute('data-date', date)
        cell.setAttribute('data-month', month + 1)
        cell.setAttribute('data-year', year)
        cell.setAttribute('data-month_name', months[month])
        cell.classList.add('date-picker')
        cell.innerHTML = `<span>${date}</span>`
        if (date === today.getDate() && year === today.getFullYear() && month === today.getMonth()) {
          cell.classList.add('selected')
        } else if (
          activedate !== '' &&
          date === activedate[1] &&
          month === activedate[0] - 1 &&
          year === activedate[2]
        ) {
          cell.classList.add('selected', 'sel')
          cell.setAttribute('aria-selected', 'true')
          cell.setAttribute('tabindex', '0')
        }

        if (
          activeDate === '' &&
          selectedDate !== '' &&
          date === selectedDate.getDate() &&
          month === selectedDate.getMonth() &&
          year === selectedDate.getFullYear()
        ) {
          cell.classList.add('selected', 'sel')
          cell.setAttribute('aria-selected', 'true')
          cell.setAttribute('tabindex', '0')
        }

        const activeDateArr = activeDate.split('-')

        if (
          activeDateArr.length &&
          date === parseInt(activeDateArr[2]) &&
          month === parseInt(activeDateArr[1]) - 1 &&
          year === parseInt(activeDateArr[0])
        ) {
          // console.log(cell)
          cell.classList.add('selected', 'sel')
          cell.setAttribute('aria-selected', 'true')
          cell.setAttribute('tabindex', '0')
        }

        if (!available_dates.includes(`${year}-${pad(month + 1, 2)}-${pad(date, 2)}`)) {
          cell.classList.add('disabled')
          cell.setAttribute('aria-disabled', 'true')
        } else {
          cell.setAttribute('aria-describedby', 'calendar-title')
          cell.setAttribute('aria-labelledby', `day${j}`)
        }
        row.append(cell)
        date += 1
      }
    }
    daydisplay.append(row)
  }
  document.querySelector('.calendar-wrap').classList.remove('hide')
  bindCalendarEvents()
}

const pad = (num, size) => {
  let num2 = num.toString()
  while (num2.length < size) num2 = `0${num2}`
  return num2
}

export const onSubmit = async (e, orderData) => {
  if (e.target.id === 'update-button' && !e.target.classList.contains('disabled')) {
    document.querySelector('.has-errors').classList.add('hide')
    if (selectedDate !== '') {
      if (!document.querySelector('input[name="toc-chk"]:checked')) {
        document.querySelector('.has-errors').innerHTML = 'Please agree to the selected date and terms'
        document.querySelector('.has-errors').classList.remove('hide')
        return false
      }

      e.target.classList.add('disabled')
      document.querySelector(
        '#update-button',
      ).innerHTML = `Change Delivery Date <img alt="loading" src=${loaderLight} />`

      const updateRes = await updateDelivery(orderData)
      if (updateRes.statusCode !== '200') {
        showErrors(
          'There was a problem rescheduling your order. Please contact customer care at 1-800-766-6786 for assistance.',
        )
      } else {
        showConfirm(orderData)
      }
    } else {
      alert('No Date Selected')
    }
  }
}

const selectDateClick = orderData => {
  document.addEventListener('click', async e => {
    if (e.target.className === 'date') {
      e.target.closest('.date-item').click()
    }
    if (e.target.className.indexOf('date-item') !== -1) {
      // handle change for date
      selectedDate = e.target.dataset.dt
      Array.from(document.querySelectorAll('.date-item.active')).map(el => {
        el.classList.remove('active')
      })
      e.target.classList.add('active')
    }
    // onSubmit(e, orderData)
  })
}

export const onPageLoad = async () => {
  const orderKey = getFromBrowserStorage('session', 'orderKey') || urlParam('orderKey')

  if (orderKey) {
    const action = '/ordersHistoryByKey'
    const orderRes = await fetch(`${ordereendpoint}${action}?orderKey=${orderKey}`)
    const orderData = await orderRes.json()

    if (orderRes.status !== 200) {
      showErrors(orderData.error.message)
    } else if (!orderData.AbleToReschedule) {
      showErrors(
        `Currently we are unable to schedule your order online, please contact customer care at 1-800-766-6786 for assistance.<br/><a href="${`${redirurl}?orderKey=${orderData.orderKey}`}">Return to Order Details</a>`,
      )
      if (document.querySelector('.main-section')) document.querySelector('.main-section').classList.add('hide')
      return false
    } else {
      buildInterface(orderData)
      const delivery_dates = await getDeliveryDates(orderData)

      let calendarDates = []
      calendarDates = orderData.DeliveryType === 'P' ? delivery_dates.pickupCalendar : delivery_dates.deliveryCalendar

      if (!calendarDates || !calendarDates.length) {
        // showErrors('Unable to retrieve calendar dates')
        if (document.querySelector('.main-section')) document.querySelector('.main-section').classList.add('hide')
        return false
      }

      if (document.querySelector('section.loading')) document.querySelector('section.loading').classList.add('hide')
      if (document.querySelector('.main-section')) document.querySelector('.main-section').classList.remove('hide')
      loadcalendar('date-selection', calendarDates)
    }
    selectDateClick(orderData)
  } else {
    showErrors('No Order ID or Zipcode')
  }
}

const bindCalendarEvents = async () => {
  document.querySelectorAll('.day.date-picker').forEach(item =>
    item.addEventListener('keydown', async e => {
      let date = date === e.target.dataset.date
      // console.log(e.target)
      // console.log(date)
      switch (e.key) {
        case 'ArrowDown':
          date = parseInt(date) + 7
          if (document.querySelector(`.day[data-date="${date}"]`))
            document.querySelector(`.day[data-date="${date}"]`).focus()
          else document.querySelector('button.next:not(.disabled)').focus()
          break
        case 'ArrowUp':
          date = parseInt(date) - 7
          if (document.querySelector(`.day[data-date="${date}"]`))
            document.querySelector(`.day[data-date="${date}"]`).focus()
          else document.querySelector('button.prev:not(.disabled)').focus()
          break
        case 'ArrowRight':
          // console.log(e.target.dataset.date)
          date += 1
          if (document.querySelector(`.day[data-date="${date}"]`))
            document.querySelector(`.day[data-date="${date}"]`).focus()
          else document.querySelector('button.next:not(.disabled)').focus()
          break
        case 'ArrowLeft':
          date -= 1
          if (document.querySelector(`.day[data-date="${date}"]`))
            document.querySelector(`.day[data-date="${date}"]`).focus()
          else document.querySelector('button.prev:not(.disabled)').focus()
          break
        case 'Enter':
        case ' ':
          e.target.click()
          break
        default:
        // console.log('def')
      }
    }),
  )
}

const setDeliveryMsg = orderData => {
  /* flags */
  const { DoorwayDelivery, upgradeDelivery } = orderData
  const deliveryType = orderData.DeliveryType

  /* DoorwayDelivery and Premium Shipping Upgrade */
  if (DoorwayDelivery === false && (deliveryType === 'D' || deliveryType === 'K')) {
    // professional delivery applied
    // document.querySelector('.deliveryinfo .address-line.upgrade').innerHTML='<span class="bl">Premium Delivery Applied</span>';
    // document.querySelector('.deliveryinfo .address-line.delivered2').innerText = 'Premium delivery includes delivery to any room, professional assembly and removal of packaging.';
    document.querySelector('.deliveryinfo .address-line.delivered2').innerHTML =
      '<span style="font-style:normal; padding-left:0px">Professional delivery and setup to any room<br/>Premium Delivery includes delivery to any room, professional assembly and removal of packaging.<br/>4-hour delivery window</span><br/><em>We will call 2 day prior with the estimated delivery window.</em>'
    document.querySelector('.deliveryinfo .address-line.delivered2').classList.remove('hide')
  } else {
    if (DoorwayDelivery === true) {
      /* doorway delivery selected- already upgraded */
      if (document.querySelector('.deliveryinfo .address-line.delivered'))
        document.querySelector('.deliveryinfo .address-line.delivered').classList.remove('hide')
      if (document.querySelector('.deliveryinfo .address-line.delivered'))
        document.querySelector('.deliveryinfo .address-line.delivered').innerText =
          'You have chosen Doorway Delivery and your order will be placed inside the garage or entryway of your home. Professional assembly and removal of packaging is not included'
    }
    if (upgradeDelivery === true && deliveryType !== 'U') {
      /* Can be upgraded */
      if (document.querySelector('.deliveryinfo .address-line.upgrade'))
        document.querySelector('.deliveryinfo .address-line.upgrade').innerHTML =
          '<span class="">Professional Delivery</span>'
    }
  }
}

const buildInterface = async orderData => {
  const delmsg = await process_delivery_data(orderData)
  if (delmsg !== '') {
    if (document.querySelector('.deliveryinfo .address-line.delivery'))
      document.querySelector('.deliveryinfo .address-line.delivery').innerHTML = delmsg
  }

  // setDeliveryMsg(orderData)

  if (document.querySelector('.main-section')) document.querySelector('.main-section').classList.remove('hide')
  if (document.querySelector('section.loading')) document.querySelector('section.loading').classList.add('hide')
}

const showErrors = errmsg => {
  if (document.querySelector('section.loading')) document.querySelector('section.loading').classList.add('hide')
  if (document.querySelector('section.errors'))
    document.querySelector('section.errors').insertAdjacentHTML('afterbegin', `<p class="error message">${errmsg}</p>`)
  if (document.querySelector('section.errors')) document.querySelector('section.errors').classList.remove('hide')
  scrollTo('content')
}

const showConfirm = orderData => {
  document
    .querySelector('section.confirm')
    .insertAdjacentHTML(
      'afterbegin',
      `<p>Your delivery date has been updated.<br/><a href="${`${redirurl}?orderKey=${orderData.orderKey}`}">Return to Order Details</a></p>`,
    )
  document.querySelector('section.confirm').classList.remove('hide')
  if (document.querySelector('.main-section')) document.querySelector('.main-section').classList.add('hide')

  window?.dataLayer.push({
    event: 'changeDeliveryDate',
    orderStatus: {
      OrderNumber: orderData.OrderNumber,
      Status: orderData.orderStatus,
      DeliveryDate: selectedDate,
      OrderDate: orderData.OrderDate,
      Balance: orderData.Balance,
      OrderTotal: orderData.OrderTotal,
      Division: orderData.Division,
      premiumDelivery: orderData.DoorwayDelivery === true,
      PaymentType: orderData.PaymentType,
    },
  })
  scrollTo('content')
}

const updateDelivery = async orderData => {
  const action = '/deliveryDate'
  const zipcode = orderData.DeliveryAddress.Zip
  const orderId = orderData.OrderNumber
  const storeNumber = orderData.StoreNumber
  const division = orderData.Division
  const deliveryDate = selectedDate
  const deliveryType = orderData.DeliveryType

  const dataObj = {
    orderId,
    storeNumber,
    zipcode,
    division,
    deliveryDate,
    deliveryType,
  }
  const dateRes = await fetch(`${ordereendpoint}${action}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataObj),
  })
  const resData = await dateRes.json()

  return resData
}

const getDeliveryDates = async orderData => {
  const action = '/reschedule'
  const zip = orderData.DeliveryAddress.Zip
  const transactionID = orderData.OrderNumber
  const isPickup = orderData.DeliveryType === 'P'
  const existingDate = orderData.DeliveryDate
  let skus = []

  if (orderData.LineItems && orderData.LineItems.LineItem && orderData.LineItems.LineItem.length) {
    orderData.LineItems.LineItem.map(item => {
      // console.log(item)

      if (item.ChildItems && item.ChildItems.LineItem && item.ChildItems.LineItem.length) {
        item.ChildItems.LineItem.map(child => {
          skus.push(child.Sku)
        })
      } else if (item.Sku.indexOf('P') === -1) skus.push(item.Sku)
    })
  }

  skus = [...new Set(skus)]

  const acctObj = {
    zip,
    transactionID,
    isPickup,
    existingDate,
    skus,
  }
  const dateRes = await fetch(`${deliveryendpoint}${action}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(acctObj),
  })
  // console.log(dateRes)
  if (dateRes.status !== 200) {
    showErrors(
      'Currently we are unable to schedule your order online, please contact customer care at 1-800-766-6786 for assistance.',
    )
    if (document.querySelector('.main-section')) document.querySelector('.main-section').classList.add('hide')
    return false
  }
  const resData = await dateRes.json()

  return resData
}

const process_delivery_data = async orderData => {
  /* generate display message for delivery info */

  /* Delivery Types
	D - Adult Delivery
	K - Kids Delivery
	C - Corporate Delivery
	X - Exports Delivery
	Z - Store Pickup
	U - UPS Delivery
	O - Vendor Delivery
	T - GiftCards
	E - Expresss Delivery
	P - Warehouse Pickup
	*/

  /* delivery data */
  const { I95ind } = orderData
  const { DeliveryDate } = orderData
  let deliveryType = orderData.DeliveryType
  const { StoreNumber } = orderData
  const { EstimatedDeliveryTimeFrame } = orderData

  let isweb = false
  if (['970', '980', '990'].includes(StoreNumber)) {
    isweb = true
  }

  if (!['D', 'K', 'C', 'X', 'Z', 'U', 'O', 'T', 'E', 'P'].includes(deliveryType)) {
    /* if no custom logic, pretends it's D */
    deliveryType = 'D'
  }

  let delmsg = ''

  if (!DeliveryDate || DeliveryDate === '' || DeliveryDate.substring(0, 4) === '0001') {
    delmsg = ''
    delmsg =
      '<strong>Estimated Delivery Notes</strong> Contact Customer Service to Schedule a Delivery Date - (800)766-6786'
    if (deliveryType === 'D' || deliveryType === 'K')
      if (document.querySelector('.read-terms p'))
        document.querySelector('.read-terms p').innerHTML =
          'I agree to the delivery date of <span class="delivery-date"></span>. I understand that the 4 hour delivery window is within 7am to 10pm and that I will be contacted approximately 2 days prior to be notified of a 4 hour delivery window. Note: Scheduled delivery dates are subject to change. '
    if (deliveryType === 'P')
      if (document.querySelector('.read-terms p'))
        document.querySelector('.read-terms p').innerHTML =
          'I confirm that I have chosen <span class="delivery-date"></span> as my pickup date. I understand that the person associated with the billing account must be present at the time of pickup with a valid, government issued ID. I acknowledge that wait time could be up to two hours and that I am responsible for the transportation and assembly of all furniture picked up. If necessary, I will reschedule my pick up date online or by calling customer service at 1-800-766-6786.'
  } else {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    const tmpdate = new Date(DeliveryDate.replace('Z', ''))

    const delts = Date.parse(DeliveryDate)
    const curts = Date.now()
    const diffts = (delts - curts) / 1000
    const theDeliveryDate = `${weekdays[tmpdate.getDay()]}, ${
      months[tmpdate.getMonth()]
    } ${tmpdate.getDate()}, ${tmpdate.getFullYear()}`
    selectedDate = tmpdate

    if (['D', 'K', 'C', 'X'].includes(deliveryType)) {
      if (I95ind === true) {
        delmsg = '<strong>Date scheduled to leave warehouse</strong>'
        if (diffts < 48 * 3600) {
          if (diffts < 0 && EstimatedDeliveryTimeFrame == '') {
            delmsg += 'Has left the warehouse.'
          } else {
            delmsg += EstimatedDeliveryTimeFrame
            // delmsg+="Should Arrive within "+(Math.ceil(diffts/3600)).toFixed(2)+" hours.";
          }
        } else {
          delmsg +=
            'Specific time frame information is not yet available.<br>Please check back in 48 hours prior to your 4 hour delivery window.'
        }
      } else {
        delmsg = `<strong>Scheduled Delivery Date</strong><span>${theDeliveryDate}</span>`
        if (diffts < 48 * 3600) {
          if (diffts < 0 && EstimatedDeliveryTimeFrame === '') {
            delmsg += `Delivered on ${theDeliveryDate}`
          } else {
            delmsg += EstimatedDeliveryTimeFrame
            // delmsg+="Should Arrive within "+(Math.ceil(diffts/3600)).toFixed(2)+" hours.";
          }
        } else {
          delmsg +=
            'Specific time frame information is not yet available.<br>Please check back in 48 hours prior to your 4 hour delivery window.'
        }
      }
    }
    if (deliveryType === 'Z') {
      delmsg =
        `<strong>Store Arrival Date</strong> <span>${theDeliveryDate}</span><br>` +
        `<div class="address-line"><strong>${orderData.PickUpData.StoreName}</strong></div>` +
        `<div class="address-line">${orderData.PickUpData.PickupAddress.Address1}</div>${
          orderData.PickUpData.PickupAddress.Address2
            ? `<div class="address-line">${orderData.PickUpData.PickupAddress.Address2}</div>`
            : ''
        }<div class="address-line">${orderData.PickUpData.PickupAddress.City} ${
          orderData.PickUpData.PickupAddress.State
        }, ${orderData.PickUpData.PickupAddress.Zip}</div>` +
        `<div class="address-line">Phone: ${orderData.PickUpData.Phone}</div>`
    }
    if (deliveryType === 'P') {
      delmsg =
        `<strong>Scheduled Pickup Date</strong> <span>${theDeliveryDate}</span><br>` +
        `<div class="address-line"><strong>${orderData.PickUpData.StoreName}</strong></div>` +
        `<div class="address-line">${orderData.PickUpData.PickupAddress.Address1}</div>${
          orderData.PickUpData.PickupAddress.Address2
            ? `<div class="address-line">${orderData.PickUpData.PickupAddress.Address2}</div>`
            : ''
        }<div class="address-line">${orderData.PickUpData.PickupAddress.City} ${
          orderData.PickUpData.PickupAddress.State
        }, ${orderData.PickUpData.PickupAddress.Zip}</div>` +
        `<div class="address-line">Phone: ${orderData.PickUpData.Phone}</div>` +
        // '<div class="address-line">Time: '+orderData.PickUpData.PickupHours.string[tmpdate.getDay()]+'</div>';
        `<div class="address-line"><strong>Pickup Hours:</strong></div>`
      if (
        orderData.PickUpData.PickupHours &&
        orderData.PickUpData.PickupHours.string &&
        orderData.PickUpData.PickupHours.string.length
      ) {
        orderData.PickUpData.PickupHours.string.map((timeframe, i) => {
          delmsg += `<div class="address-line"><span class="day">${weekdays[i]}</span> -&nbsp;&nbsp; ${timeframe}</div>`
        })
      }
    }
    if (deliveryType === 'U') {
      if (isweb) {
        delmsg =
          '<strong>Estimated Delivery Notes</strong> Please check your Order confirmation email for the delivery estimates or call Customer Service at (800)766-6786'
      } else {
        delmsg = `<strong>Estimated Delivery Date</strong> ${theDeliveryDate}`
      }
    }
    if (deliveryType === 'O') {
      if (isweb) {
        delmsg = '<strong>Ships Direct from Vendor</strong>' // Estimated delivery date: "+theDeliveryDate;
      } else {
        delmsg = '<strong>Ships Direct from Vendor</strong>'
      }
    }
    if (deliveryType === 'T') {
      if (isweb) {
        delmsg = '<strong>Estimated Delivery Notes</strong> Mailed to Billing Address via USPS'
      } else {
        delmsg = `<strong>Estimated Delivery Notes</strong> Contact Showroom for Pickup Date: ${orderData.PickUpData.StoreName}`
      }
    }

    if (deliveryType === 'E') {
      delmsg = `<strong>Scheduled Delivery Date</strong><span>${theDeliveryDate}</span>`
      if (diffts < 48 * 3600) {
        if (diffts < 0 && EstimatedDeliveryTimeFrame === '') {
          delmsg += `Delivered on ${theDeliveryDate}`
        } else {
          delmsg += EstimatedDeliveryTimeFrame
          // delmsg+="Should Arrive within "+(Math.ceil(diffts/3600)).toFixed(2)+" hours.";
        }
      } else {
        delmsg =
          '<strong>Estimated Delivery Notes</strong> Your delivery has an all day delivery window (7a - 10p). We are unable to establish a specific delivery window because Express Delivery requires such a quick turnaround.'
      }
    }

    if (deliveryType === 'D' || deliveryType === 'K')
      if (document.querySelector('.read-terms p'))
        document.querySelector(
          '.read-terms p',
        ).innerHTML = `I agree to the delivery date of <span class="delivery-date">${theDeliveryDate}</span>. I understand that the 4 hour delivery window is within 7am to 10pm and that I will be contacted approximately 2 days prior to be notified of a 4 hour delivery window. Note: Scheduled delivery dates are subject to change. `
    if (deliveryType === 'P')
      if (document.querySelector('.read-terms p'))
        document.querySelector(
          '.read-terms p',
        ).innerHTML = `I confirm that I have chosen <span class="delivery-date">${theDeliveryDate}</span> as my pickup date. I understand that the person associated with the billing account must be present at the time of pickup with a valid, government issued ID. I acknowledge that wait time could be up to two hours and that I am responsible for the transportation and assembly of all furniture picked up. If necessary, I will reschedule my pick up date online or by calling customer service at 1-800-766-6786.`
  }

  return delmsg
}

export default onSubmit
