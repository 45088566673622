import { getOrderHistoryByKey, getOrderDetails, rescheduleDelivery } from '@services/order-status'
import { navigate } from 'gatsby'
import { getFromBrowserStorage } from '@helpers/storage'
import { getURLParam } from '@helpers/general'

export const createDeliveryObject = ({ order }) => {
  let deliveryObject = {}
  if (order) {
    let skus = []

    if (order.LineItems && order.LineItems.LineItem && order.LineItems.LineItem.length) {
      order.LineItems.LineItem.map(item => {
        // console.log(item)
        if (item?.ChildItems?.LineItem?.length) {
          item.ChildItems.LineItem.map(child => skus.push(child.Sku))
        } else if (item.Sku.indexOf('P') === -1) {
          return skus.push(item.Sku)
        }
        return skus
      })
    }

    skus = [...new Set(skus)]

    deliveryObject = {
      existingDate: order.DeliveryDate,
      isPickup: order.DeliveryType === 'P',
      skus,
      transactionID: order.OrderNumber,
      zip: order.DeliveryAddress.Zip,
    }
  }
  return deliveryObject
}

export const getDeliveryDates = (deliveryObject, setLoading) => {
  setLoading(true)
  return rescheduleDelivery(deliveryObject).then(
    data =>
      new Promise((resolve, reject) => {
        setLoading(false)
        if (data?.auth === 'success') {
          navigate('/order/thankyou')
          resolve()
        } else {
          reject(new Error(`Unable to process payment: ${data}`))
        }
        // console.log(data)
        if (data.status !== 200) {
          // showErrors(
          //   'Currently we are unable to schedule your order online, please contact customer care at 1-800-766-6786 for assistance.',
          // )
          // document.querySelector('.main-section').classList.add('hide');
          return false
        }
        return data.json()
      }),
  )
}

export const getOrderOnRefresh = ({ dispatch, setOrder, setOrderList, setOrderNumber }) => {
  const orderKey = getFromBrowserStorage('session', 'orderKey') || getURLParam('orderKey')
  const orderNumber = getFromBrowserStorage('session', 'orderNumber') || getURLParam('orderNumber')
  const orderId = getURLParam('orderId')
  const zipcode = getURLParam('zipcode')

  const saveOrderObj = data => {
    let order = {}
    let orderList
    if (data) {
      orderList = [data]
      if (typeof orderNumber !== 'object') {
        const newOrder = orderList.filter(item => item.OrderNumber.toString() === orderNumber.toString())[0]
        order = newOrder
      } else {
        order = data
      }
    }
    dispatch(setOrder(order))
    dispatch(setOrderList(orderList))
    dispatch(setOrderNumber(order?.OrderNumber))
  }

  if (orderKey && orderNumber) {
    getOrderHistoryByKey(orderKey).then(data => {
      saveOrderObj(data)
    })
  } else if (orderId && zipcode) {
    getOrderDetails(orderId, zipcode).then(data => {
      saveOrderObj(data)
    })
  }
}
